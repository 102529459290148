// primary colors
$primary: var(--primary-color);
$light-blue1: var(--light-blue1);
$light-blue2: var(--light-blue2);

// secondary colors
$secondary-orange: var(--secondary-orange);
$secondary-yellow: var(--secondary-yellow);
$secondary-green: var(--secondary-green);
$secondary-emerald: var(--secondary-emerald);
$secondary-dark-emerald: var(--secondary-dark-emerald);
$secondary-light-blue: var(--secondary-light-blue);
$secondary-blue: var(--secondary-blue);
$secondary-dark-blue1: var(--secondary-dark-blue1);
$secondary-dark-blue2: var(--secondary-dark-blue2);

// gray colors
$gray: var(--gray);
$dark-gray3: var(--dark-gray3);
$dark-gray2: var(--dark-gray2);
$dark-gray1: var(--dark-gray1);
$light-gray4: var(--light-gray4);
$light-gray3: var(--light-gray3);
$light-gray2: var(--light-gray2);
$light-gray1: var(--light-gray1);

// basic colors
$black: var(--black);
$white: var(--white);

// banner colors
$first-medium-banner: var(--first-medium-banner);
$second-medium-banner: var(--second-medium-banner);

// promotion colors
$first-sub-promotion-button: var(--first-sub-promotion-button);
$second-sub-promotion-button: var(--second-sub-promotion-button);
