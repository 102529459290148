@mixin small-mobile {
  @media only screen and (max-width: 500px) {
    @content;
  }
}
@mixin mobile {
  @media only screen and (width <= 1023px) {
    @content;
  }
}
@mixin desktop {
  @media only screen and (width >= 1024px) {
    @content;
  }
}
