@import 'media';

@mixin heading1 {
  @include desktop {
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 64px;
    letter-spacing: -2px;
  }
  @include mobile {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: -1px;
  }
}
@mixin heading1-bold {
  @include desktop {
    font-weight: bold;
    font-size: 48px;
    line-height: 64px;
    letter-spacing: -2px;
  }
  @include mobile {
    font-weight: bold;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: -1px;
  }
}
@mixin heading2 {
  @include desktop {
    font-weight: normal;
    font-size: 40px;
    line-height: 52px;
    letter-spacing: -1px;
  }
  @include mobile {
    font-weight: normal;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -1px;
  }
}
@mixin heading2-bold {
  @include desktop {
    font-weight: bold;
    font-size: 40px;
    line-height: 52px;
    letter-spacing: -1px;
  }
  @include mobile {
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -1px;
  }
}
@mixin sub1 {
  @include desktop {
    font-weight: normal;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: -0.75px;
  }
  @include mobile {
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -1px;
  }
}
@mixin sub1-bold {
  @include desktop {
    font-weight: bold;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: -0.75px;
  }
  @include mobile {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -1px;
  }
}
@mixin sub2 {
  @include desktop {
    font-weight: normal;
    font-size: 26px;
    line-height: 36px;
    letter-spacing: -0.75px;
  }
  @include mobile {
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.5px;
  }
}
@mixin sub2-bold {
  @include desktop {
    font-weight: bold;
    font-size: 26px;
    line-height: 36px;
    letter-spacing: -0.75px;
  }
  @include mobile {
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.5px;
  }
}
@mixin body1 {
  @include desktop {
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.5px;
  }
  @include mobile {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
  }
}
@mixin body1-bold {
  @include desktop {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.5px;
  }
  @include mobile {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
  }
}
@mixin caption1 {
  @include desktop {
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.5px;
  }
  @include mobile {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.5px;
  }
}
@mixin caption1-bold {
  @include desktop {
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.5px;
  }
  @include mobile {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.5px;
  }
}
@mixin caption2 {
  @include desktop {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
  }
  @include mobile {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.5px;
  }
}
@mixin caption2-bold {
  @include desktop {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
  }
  @include mobile {
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.5px;
  }
}
@mixin caption3 {
  @include desktop {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.5px;
  }
  @include mobile {
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: -0.5px;
  }
}
@mixin caption3-bold {
  @include desktop {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.5px;
  }
  @include mobile {
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: -0.5px;
  }
}
@mixin caption4 {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.5px;
}
@mixin caption4-regular {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.5px;
}
@mixin caption4-bold {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.5px;
}
