@import './palette';

.text-color__primary {
  color: $primary;
}

.text-color__light-blue1 {
  color: $light-blue1;
}

.text-color__light-blue2 {
  color: $light-blue2;
}

.text-color__secondary-orange {
  color: $secondary-orange;
}

.text-color__secondary-yellow {
  color: $secondary-yellow;
}

.text-color__secondary-green {
  color: $secondary-green;
}

.text-color__secondary-emerald {
  color: $secondary-emerald;
}

.text-color__secondary-dark-emerald {
  color: $secondary-dark-emerald;
}

.text-color__secondary-light-blue {
  color: $secondary-light-blue;
}

.text-color__secondary-blue {
  color: $secondary-blue;
}

.text-color__secondary-dark-blue1 {
  color: $secondary-dark-blue1;
}

.text-color__secondary-dark-blue2 {
  color: $secondary-dark-blue2;
}

.text-color__gray {
  color: $gray;
}

.text-color__dark-gray1 {
  color: $dark-gray1;
}

.text-color__dark-gray2 {
  color: $dark-gray2;
}

.text-color__dark-gray3 {
  color: $dark-gray3;
}

.text-color__light-gray1 {
  color: $light-gray1;
}

.text-color__light-gray2 {
  color: $light-gray2;
}

.text-color__light-gray3 {
  color: $light-gray3;
}

.text-color__light-gray4 {
  color: $light-gray1;
}

.text-color__black {
  color: $black;
}

.text-color__white {
  color: $white;
}
