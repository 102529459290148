$asset-path: "https://files.booking-on.com";
@import 'variables';
@import 'typography';
@import 'components';

:root {
  --primary-color: #073291;
  --light-blue1: #59afff;
  --light-blue2: #f0f4fb;

  // secondary colors
  --secondary-orange: #f2705d;
  --secondary-yellow: #eeb76f;
  --secondary-green: #83e2b7;
  --secondary-emerald: #0fcbe2;
  --secondary-dark-emerald: #21a8b8;
  --secondary-light-blue: #cbe0ff;
  --secondary-blue: #4947be;
  --secondary-dark-blue1: #25245f;
  --secondary-dark-blue2: #1a2f4e;

  // gray colors
  --gray: #8e8e8e;
  --dark-gray3: #0c0c0c;
  --dark-gray2: #292929;
  --dark-gray1: #515151;
  --light-gray4: #fafafa;
  --light-gray3: #f8f8f8;
  --light-gray2: #eaeaea;
  --light-gray1: #cbcbcb;

  // basic colors
  --black: #000;
  --white: #fff;

  // banner colors
  --first-medium-banner: #f2f9ff;
  --second-medium-banner: #877669;

  // promotion colors
  --first-sub-promotion-button: #ffb71b;
  --second-sub-promotion-button: #877669;
}

* {
  font-family: "Spoqa Han Sans Neo",
  sans-serif;
}

body {
  color: $dark-gray3;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img,
svg {
  vertical-align: middle;
}

a {
  text-decoration-line: none;
  color: inherit;

  &:hover {
    color: inherit;
  }
}

img[src$='.gif'],
img[src$='.png'] {
  image-rendering: crisp-edges;
  transform: translateZ(0);
  backface-visibility: hidden;
}

.hide{
  display: none !important;
}

.hidden-lazy-load {
  height: 20px;
  width: 100%;
}
