.link-button {
  display: inline;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  color: inherit;

  &:hover,
  :focus {
    background-color: transparent;
    text-decoration: none;
  }
}
