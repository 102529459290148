@import 'mixins';

.heading1-bold {
  @include heading1-bold;
}

.heading1 {
  @include heading1;
}

.heading2 {
  @include heading2;
}

.heading2-bold {
  @include heading2-bold;
}

.sub1 {
  @include sub1;
}

.sub1-bold {
  @include sub1-bold;
}

.sub2 {
  @include sub2-bold;
}

.sub2-regular {
  @include sub2-bold;
}

.sub2-bold {
  @include sub2-bold;
}

.body1 {
  @include body1;
}

.body1-bold {
  @include body1-bold;
}

.caption1 {
  @include caption1;
}

.caption1-bold {
  @include caption1-bold;
}

.caption2 {
  @include caption2;
}

.caption2-bold {
  @include caption2-bold;
}

.caption3 {
  @include caption3;
}

.caption3-bold {
  @include caption3-bold;
}

.caption4 {
  @include caption4;
}

.caption4-regular {
  @include caption4-regular
}

.caption4-bold {
  @include caption4-bold;
}
